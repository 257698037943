import { computed, onMounted, reactive, ref, toRaw } from "vue";
import moment from 'moment'
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { getDealerPayoutCondition,
  getRoundListByDate,fetchListDealerSetting,
  saveConditionDealerStatus, deleteCondition, conditionAllDealerStatus } from "@/API/payment";
import { message } from "ant-design-vue";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import { DataProps } from "@/views/Payment/Controlling/Dealer/DealerStatusTracking/type";
export interface FavoriteTableData {
  id: string;
  year: string;
  month: string;
  payoutRound: string;
  entity: string;
}
const useDealerStatusTracking = () => {
  const entityRef = ref()
  // 表头
  const column = ref([
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerNameCN",
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "amountWOVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: "Payout Amount w VAT",
      dataIndex: "amountWVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus"
    }
  ]);
  // 查询条件
  const query = reactive({
    year: "",
    month: moment(),
    payoutRound: "",
    entity: "",
    dealerName: '',
    dealerCode: '',
    region: '',
    dealerGroup: '',
  });
  // 获取折叠面板表格数据
  const res = ref();
  const NormalDelearData = computed(() => {
    return res.value?.normalDealer;
  });
  const RiskDelearData = computed(() => {
    return res.value?.riskDealer;
  });
  // 查询round列表
  const roundRes = ref()
  const roundList = computed(() => {
    return roundRes.value ? roundRes.value : [];
  });
  const searchRound = () => {
    roundRes.value = null
    query.payoutRound = ""
    getRoundListByDate({
      params: {
        month: moment(query.month).format("M"),
        year: moment(query.year).format("YYYY"),
        type: "DEALER",
      },
    }).then((res) => {
      roundRes.value = res;
    });
  };
  const activeKey = ref<string[]>([]);
  const search = () => {
    const _query = {...toRaw(query)} as any
    _query.month = query.month.format("M")
    _query.year = moment(query.year).format("YYYY")
    console.log(toRaw(_query))
    fetchListDealerSetting(toRaw(_query)).then((data) => {
      res.value = data;
      activeKey.value = []
      if (data.riskDealer !== '') {
        activeKey.value.push('1')
      } 
      if (data.normalDealer !== '') {
        activeKey.value.push('2')
      }
    })
  };
  const initCondition = () => {
    return new Promise((resolve) => {
      getDealerPayoutCondition().then((res) => {
        query.month = moment().year(res.year).month(res.month-1)
        query.year = res.year.toString();
        resolve(res);
      });
    });
  };
  onMounted(() => {
    // console.log('onMounted')
    initCondition().then(() => {
      searchRound()
      search();
    });
  });
  const reset = () => {
    roundRes.value = null
    query.payoutRound = ''
    entityRef.value.reset()
    initCondition().then(() => {
      searchRound()
      search();
    });
  }

  const isFavoriteVisible = ref(false)
  const saveFavorite = () => {
    const params = {
      year: moment(query.year).format("YYYY"),
      month: moment(query.month).format("M"),
      payoutRound: query.payoutRound,
      entity: query.entity
    }
    saveConditionDealerStatus(params).then(() => {
      message.success('Sucessfully Saved!')
    })
  }
  const favoriteColumns = ref([
    {
      title: "Payout Year",
      dataIndex: "year",
    },
    {
      title: "Payout Month",
      dataIndex: "month",
    },
    {
      title: "Payout Round",
      dataIndex: "payoutRound"
    },
    {
      title: "Entity",
      dataIndex: "entity"
    },
    {
      title: "Operation",
      key: "operation",
      slots: { customRender: "action"}
    }
  ])
  const favoriteData = ref<FavoriteTableData[]>([])
  const getFavoriteList = () => {
    conditionAllDealerStatus().then((data: any) => {
      favoriteData.value = data
    })
  }
  const showFavorite = () => {
    isFavoriteVisible.value = true
    getFavoriteList()
  }
  const closeFavorite = () => {
    isFavoriteVisible.value = false
  }
  // const customRow = (record: FavoriteTableData, index: number): { onClick: Function } => {
  //   return {
  //     onClick: () => {
  //       query.year = record.year
  //       query.month = record.month
  //       query.payoutRound = record.payoutRound
  //       query.entity = record.entity
  //       closeFavorite()
  //       search()
  //     }
  //   }
  // }
  const deleteFavorite = (record: FavoriteTableData) => {
    deleteCondition(record.id).then(() => {
      message.success('Sucessfully Delete!')
      getFavoriteList()
    })
  }
  // 斑马纹
  const getRowClass = useStripedRowClass<DataProps>();
  return {
    search,
    reset,
    searchRound,
    query,
    column,
    NormalDelearData,
    RiskDelearData,
    roundList,
    isFavoriteVisible,
    showFavorite,
    closeFavorite,
    saveFavorite,
    favoriteColumns,
    favoriteData,
    // customRow,
    deleteFavorite,
    getRowClass,
    entityRef,
    activeKey
  };
};
export default useDealerStatusTracking;
