
import { defineComponent, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
import YearPicker from "@/views/Payment/components/YearPicker.vue";
import collapseTable from "./collapseTable.vue";
import useDealerStatusTracking from "@/hooks/payment/controlling/Dealer/DealerStatusTracking/useDealerStatusTracking";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import downloadFile from "@/utils/payment/downloadFile";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "DealerSetting",
  props: {},
  setup() {
    // const { search, reset, searchRound, query,
    // NormalDelearData, RiskDelearData, column,
    // roundList,
    // isFavoriteVisible, showFavorite, closeFavorite, saveFavorite,
    // favoriteColumns, favoriteData, customRow, deleteFavorite, getRowClass, entityRef, activeKey } = useDealerStatusTracking();
    const {
      search,
      reset,
      searchRound,
      query,
      NormalDelearData,
      RiskDelearData,
      column,
      roundList,
      isFavoriteVisible,
      showFavorite,
      closeFavorite,
      saveFavorite,
      favoriteColumns,
      favoriteData,
      deleteFavorite,
      getRowClass,
      entityRef,
      activeKey,
    } = useDealerStatusTracking();

    const handleDownload = () => {
      if (!query.payoutRound) {
        return false;
      }
      const params = {
        url: `/ptapi/overview/trackingLogic1/${query.payoutRound}/''/''`,
        method: "get",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    };

    return {
      open,
      search,
      reset,
      searchRound,
      query,
      activeKey,
      roundList,
      NormalDelearData,
      RiskDelearData,
      column,
      isFavoriteVisible,
      showFavorite,
      closeFavorite,
      saveFavorite,
      favoriteColumns,
      favoriteData,
      // customRow,
      deleteFavorite,
      getRowClass,
      entityRef,
      handleDownload,
      toThousandsAndTwoDecimal
    };
  },
  components: {
    RightOutlined,
    collapseTable,
    YearPicker,
    EntitySelector,
  },
});
